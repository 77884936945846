// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as ServiceSection from "../../../../../styleguide/components/ServiceSection/ServiceSection.res.js";
import * as Routes_Services from "../../../../../routes/common/Routes_Services.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ServiceSectionProviderBox from "../../../../../styleguide/components/ServiceSection/components/provider-box/ServiceSectionProviderBox.res.js";
import * as HomeBareMetalSectionScss from "./HomeBareMetalSection.scss";
import LogoEnzuPng from "Images/companies/logo-enzu.png";
import HivelocityPng from "Images/companies/hivelocity/hivelocity.png";
import EvocativeLogoPng from "Images/companies/evocative/evocative-logo.png";
import ZenlayerHorizontalPng from "Images/companies/zenlayer/zenlayer-horizontal.png";

var css = HomeBareMetalSectionScss;

function HomeBareMetalSection(props) {
  return JsxRuntime.jsxs(ServiceSection.make, {
              title: "Bare Metal Services",
              subtitle: "Rapid deployment of on-demand bare metal servers in more than 25 countries. High-performance computing power with the flexibility of the cloud.",
              background: "Blue",
              contentDirection: "Column",
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.AsLink.make, {
                              href: Routes_Project.BareMetal.$$new,
                              size: "MD",
                              color: "Teal",
                              className: css.button,
                              children: "Bare Metal Pricing"
                            }),
                        JsxRuntime.jsx(Button.AsLink.make, {
                              href: Routes_Services.BareMetal.index,
                              size: "MD",
                              color: "Clear",
                              ghost: true,
                              className: css.button,
                              children: "Bare Metal Services"
                            })
                      ],
                      className: css.buttons
                    }),
                JsxRuntime.jsx("hr", {
                      className: css.divider
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Bare Metal Providers",
                              className: css.providersTitle
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(ServiceSectionProviderBox.make, {
                                      logo: LogoEnzuPng,
                                      description: "Predictable, high-powered performance for even the most demanding workloads",
                                      link: Routes_Services.BareMetal.enzu
                                    }),
                                JsxRuntime.jsx(ServiceSectionProviderBox.make, {
                                      logo: ZenlayerHorizontalPng,
                                      description: "Complete control with servers dedicated exclusively to your business",
                                      link: Routes_Services.BareMetal.zenlayer
                                    }),
                                JsxRuntime.jsx(ServiceSectionProviderBox.make, {
                                      logo: HivelocityPng,
                                      description: "Dedicated server hosting with predictable costs. Bare metal servers deployed instantly in 40+ global markets.",
                                      link: Routes_Services.BareMetal.hivelocity
                                    }),
                                JsxRuntime.jsx(ServiceSectionProviderBox.make, {
                                      logo: EvocativeLogoPng,
                                      description: "Deploy enterprise-grade servers across 11 global metros on-demand.",
                                      link: Routes_Services.BareMetal.evocative
                                    })
                              ],
                              className: css.providerBoxes
                            })
                      ],
                      className: css.providers
                    })
              ]
            });
}

var make = HomeBareMetalSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
