// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import ReactLazyload from "react-lazyload";
import * as JsxRuntime from "react/jsx-runtime";

function LazyBackgroundImage(props) {
  return JsxRuntime.jsx(ReactLazyload, {
              height: props.height,
              offset: props.offset,
              children: JsxRuntime.jsx("div", {
                    children: Belt_Option.getWithDefault(props.children, null),
                    className: props.className,
                    style: {
                      backgroundImage: "url(" + (props.src + ")")
                    }
                  })
            });
}

var make = LazyBackgroundImage;

export {
  make ,
}
/* react-lazyload Not a pure module */
