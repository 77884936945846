// Generated by ReScript, PLEASE EDIT WITH CARE

import ReactLazyload from "react-lazyload";
import * as JsxRuntime from "react/jsx-runtime";

function LazyImage(props) {
  return JsxRuntime.jsx(ReactLazyload, {
              height: props.height,
              offset: props.offset,
              children: JsxRuntime.jsx("img", {
                    className: props.className,
                    src: props.src
                  })
            });
}

var make = LazyImage;

export {
  make ,
}
/* react-lazyload Not a pure module */
