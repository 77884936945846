// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ServiceSection from "../../../../../styleguide/components/ServiceSection/ServiceSection.res.js";
import * as Routes_Location from "../../../../../routes/common/Routes_Location.res.js";
import * as ServiceSectionBox from "../../../../../styleguide/components/ServiceSection/components/service-box/ServiceSectionBox.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../../../routes/common/Routes_Marketplace.res.js";
import * as ServicesSeparatorBlock from "../../../../services/ServicesSeparatorBlock.res.js";
import * as Routes_LocationBrochure from "../../../../../routes/common/Routes_LocationBrochure.res.js";

function HomeColocationSection(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ServicesSeparatorBlock.make, {
                      title: "Get Colocation Now"
                    }),
                JsxRuntime.jsxs(ServiceSection.make, {
                      title: "Colocation Services",
                      subtitle: "Datacenters.com makes it easy to get colocation quotes from multiple colocation providers. Get started by entering your colocation service requirements.",
                      background: "White",
                      children: [
                        JsxRuntime.jsx(ServiceSectionBox.make, {
                              icon: "Marketplace",
                              title: "Colocation Pricing",
                              description: "Explore competitive colocation rates for top data centers in our Colocation Marketplace.",
                              link: Routes_Marketplace.colocation,
                              buttonTitle: "Get Colocation Pricing"
                            }),
                        JsxRuntime.jsx(ServiceSectionBox.make, {
                              icon: "Locations",
                              title: "Colocation Directory",
                              description: "Discover top data center locations worldwide with detailed information on each site.",
                              link: Routes_Location.index,
                              buttonTitle: "Search Locations"
                            }),
                        JsxRuntime.jsx(ServiceSectionBox.make, {
                              icon: "Brochures",
                              title: "Data Center Brochures",
                              description: "Search and download detailed brochures for various data centers, showcasing their features and benefits.",
                              link: Routes_LocationBrochure.index,
                              buttonTitle: "Download Brochures"
                            })
                      ]
                    })
              ]
            });
}

var make = HomeColocationSection;

export {
  make ,
}
/* ServiceSection Not a pure module */
