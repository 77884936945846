// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../Heading/H2.res.js";
import * as H3 from "../Heading/H3.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ServiceSectionScss from "./ServiceSection.scss";

var css = ServiceSectionScss;

function bgToClass(background) {
  switch (background) {
    case "White" :
        return css.white;
    case "Gray" :
        return css.gray;
    case "Blue" :
        return css.blue;
    
  }
}

function ServiceSection(props) {
  var children = props.children;
  var className = props.className;
  var __contentDirection = props.contentDirection;
  var __background = props.background;
  var background = __background !== undefined ? __background : "White";
  var contentDirection = __contentDirection !== undefined ? __contentDirection : "Row";
  var $$class = className !== undefined ? className : "";
  var tmp;
  tmp = contentDirection === "Row" ? JsxRuntime.jsx("div", {
          children: children,
          className: Cx.cx([
                css.contentRow,
                $$class
              ])
        }) : JsxRuntime.jsx("div", {
          children: children,
          className: Cx.cx([
                css.contentColumn,
                $$class
              ])
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: props.title
                    }),
                JsxRuntime.jsx(H3.make, {
                      className: css.subtitle,
                      children: props.subtitle
                    }),
                tmp
              ],
              className: Cx.cx([
                    css.serviceSection,
                    bgToClass(background)
                  ])
            });
}

var make = ServiceSection;

export {
  css ,
  bgToClass ,
  make ,
}
/* css Not a pure module */
