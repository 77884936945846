// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ServiceSection from "../../../../../styleguide/components/ServiceSection/ServiceSection.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexProvider from "../../../../locations/index/components/common/LocationsIndexProvider.res.js";
import * as HomeTopProvidersSectionScss from "./HomeTopProvidersSection.scss";

var css = HomeTopProvidersSectionScss;

function HomeTopProvidersSection(props) {
  var providers = props.providers;
  return JsxRuntime.jsx(ServiceSection.make, {
              title: "Top " + String(providers.length) + " Providers with Data Centers",
              subtitle: "Find providers that offer colocation, bare metal, cloud and managed services.",
              background: "Gray",
              contentDirection: "Column",
              className: css.topProvidersSection,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      Belt_Array.mapWithIndex(providers, (function (index, provider) {
                              return JsxRuntime.jsx(LocationsIndexProvider.make, {
                                          name: provider.name,
                                          slug: provider.slug,
                                          totalLocations: provider.totalLocations
                                        }, String(index));
                            })),
                      JsxRuntime.jsx("a", {
                            children: "View All Providers",
                            className: css.providerLink,
                            href: Routes_Provider.index
                          })
                    ],
                    className: css.topProviders
                  })
            });
}

var make = HomeTopProvidersSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
