// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as BlogPostTile from "../../../../../blog-posts/common/blog-post-tile/BlogPostTile.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeBlogPostGridScss from "./HomeBlogPostGrid.scss";

var css = HomeBlogPostGridScss;

function HomeBlogPostGrid(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.map(props.blogPosts, (function (blogPost) {
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(BlogPostTile.make, {
                                              blogPost: blogPost
                                            }),
                                        className: css.column
                                      }, ID.toString(blogPost.id));
                          })),
                    className: css.row
                  }),
              className: css.container
            });
}

var make = HomeBlogPostGrid;

export {
  css ,
  make ,
}
/* css Not a pure module */
