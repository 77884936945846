// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HorizontalScrollScss from "./HorizontalScroll.scss";

var css = HorizontalScrollScss;

function HorizontalScroll(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    css.wrapper,
                    className
                  ])
            });
}

var make = HorizontalScroll;

export {
  css ,
  make ,
}
/* css Not a pure module */
