// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronTitleScss from "./HomeJumbotronTitle.scss";

var css = HomeJumbotronTitleScss;

function HomeJumbotronTitle(props) {
  return JsxRuntime.jsx("div", {
              children: props.title,
              className: css.title
            });
}

var make = HomeJumbotronTitle;

export {
  css ,
  make ,
}
/* css Not a pure module */
