// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H4 from "../../../Heading/H4.res.js";
import * as Button from "../../../Button/Button.res.js";
import * as LazyImage from "../../../LazyImage/LazyImage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ServiceSectionBoxScss from "./ServiceSectionBox.scss";
import IconTilesSvg from "../../../../assets/svgs/icon-tiles.svg";
import IconMapPinsSvg from "../../../../assets/svgs/icon-map-pins.svg";
import IconControlCostsSvg from "../../../../assets/svgs/icon-control-costs.svg";

var iconCosts = IconControlCostsSvg;

var iconMap = IconMapPinsSvg;

var iconBrochures = IconTilesSvg;

var css = ServiceSectionBoxScss;

function bgToClass(background) {
  if (background === "White") {
    return css.white;
  } else {
    return css.gray;
  }
}

function iconToString(icon) {
  switch (icon) {
    case "Marketplace" :
        return iconCosts;
    case "Brochures" :
        return iconBrochures;
    case "Locations" :
        return iconMap;
    
  }
}

function ServiceSectionBox(props) {
  var __background = props.background;
  var background = __background !== undefined ? __background : "White";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LazyImage.make, {
                      src: iconToString(props.icon),
                      height: 60,
                      offset: 100,
                      className: css.icon
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H4.make, {
                              className: css.title,
                              children: props.title
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.description,
                              className: css.description
                            })
                      ],
                      className: css.content
                    }),
                JsxRuntime.jsx(Button.AsLink.make, {
                      href: props.link,
                      size: "SM",
                      color: "Teal",
                      children: props.buttonTitle
                    })
              ],
              className: Cx.cx([
                    css.box,
                    bgToClass(background)
                  ])
            });
}

var make = ServiceSectionBox;

export {
  iconCosts ,
  iconMap ,
  iconBrochures ,
  css ,
  bgToClass ,
  iconToString ,
  make ,
}
/* iconCosts Not a pure module */
