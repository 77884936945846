// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Globals from "Common/globals";

function youtubeThumbUrl(publicId) {
  return "https://res.cloudinary.com/" + Globals.CloudinaryCloudName + "/image/youtube/" + publicId + ".jpg";
}

function vimeoThumbUrl(publicId) {
  return "https://res.cloudinary.com/" + Globals.CloudinaryCloudName + "/image/vimeo/" + publicId + ".jpg";
}

function imageUrl(publicId) {
  return "https://res.cloudinary.com/" + Globals.CloudinaryCloudName + "/image/upload/" + publicId;
}

function toString(x) {
  switch (x) {
    case "Png" :
        return "png";
    case "Jpg" :
    case "Auto" :
        return "jpg";
    
  }
}

function toParam(x) {
  switch (x) {
    case "Jpg" :
        return "jpg";
    case "Png" :
        return "png";
    case "Auto" :
        return "auto";
    
  }
}

var Format = {
  toString: toString,
  toParam: toParam
};

function toParam$1(x) {
  switch (x) {
    case "Fit" :
        return "fit";
    case "MinFit" :
        return "mfit";
    case "Fill" :
        return "fill";
    case "LimitFill" :
        return "lfill";
    case "Pad" :
        return "pad";
    case "FillPad" :
        return "fill_pad";
    case "MinPad" :
        return "mpad";
    case "LimitPad" :
        return "lpad";
    case "Crop" :
        return "crop";
    case "Limit" :
        return "limit";
    case "Thumb" :
        return "thumb";
    case "Scale" :
        return "scale";
    
  }
}

var Crop = {
  toParam: toParam$1
};

function toUrlParam(x) {
  if (typeof x !== "object") {
    if (x === "Quality") {
      return "q_auto";
    } else {
      return "dpr_auto";
    }
  }
  switch (x.TAG) {
    case "Width" :
        return "w_" + String(x._0);
    case "Height" :
        return "h_" + String(x._0);
    case "FetchFormat" :
        return "f_" + toParam(x._0);
    case "Crop" :
        return "c_" + toParam$1(x._0);
    
  }
}

function build(transformations) {
  var __x = Belt_List.map(transformations, toUrlParam);
  return $$String.concat(",", __x);
}

function inject(url, transformations) {
  var __x = (function (__x) {
        return Js_string.replace("youtube", "youtube/" + transformations, __x);
      })((function (__x) {
            return Js_string.replace("facebook", "facebook/" + transformations, __x);
          })((function (__x) {
                return Js_string.replace("fetch", "fetch/" + transformations, __x);
              })((function (__x) {
                    return Js_string.replace("upload", "upload/" + transformations, __x);
                  })(url))));
  return Js_string.replace("vimeo", "vimeo/" + transformations, __x);
}

var Transformations = {
  Format: Format,
  Crop: Crop,
  toUrlParam: toUrlParam,
  build: build,
  inject: inject
};

function hasExt(url) {
  var extensionLength = Js_string.substrAtMost((function (__x) {
            return Js_string.lastIndexOf(".", __x);
          })(url) + 1 | 0, url.length, url).length;
  return extensionLength <= 4;
}

function setFormat(format, url) {
  var match = hasExt(url);
  if (format !== undefined) {
    if (match) {
      var __x = Js_string.substrAtMost(0, (function (__x) {
                return Js_string.lastIndexOf(".", __x);
              })(url), url);
      return Js_string.concatMany([
                  ".",
                  toString(format)
                ], __x);
    } else {
      return url + ("." + toString(format));
    }
  } else {
    return url;
  }
}

function build$1(format, transformations, url) {
  var __x = (function (__x) {
        return inject(__x, build(transformations));
      })(url);
  return setFormat(format, __x);
}

var Url = {
  hasExt: hasExt,
  setFormat: setFormat,
  build: build$1
};

var VideoPlayer = {};

export {
  youtubeThumbUrl ,
  vimeoThumbUrl ,
  imageUrl ,
  Transformations ,
  Url ,
  VideoPlayer ,
}
/* Common/globals Not a pure module */
