// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var FeaturedImageId = ID.Int({});

function fromJs(js) {
  return {
          id: js.id,
          title: js.title,
          body: js.body,
          seoTitle: js.seoTitle,
          metaDescription: js.metaDescription,
          featuredImage: js.featuredImage,
          slug: js.slug,
          approved: js.approved,
          blogPostCategoryId: js.blogPostCategoryId,
          publishedAt: $$Date.Naive.fromString(js.publishedAt),
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.createdAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              body: field.required("body", Json_Decode$JsonCombinators.string),
              seoTitle: field.required("seoTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              featuredImage: field.required("featuredImage", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool),
              blogPostCategoryId: field.required("blogPostCategoryId", ID.decoder),
              publishedAt: field.required("publishedAt", $$Date.Naive.decoder),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Edit = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          id: js.id,
          title: js.title,
          category: js.category,
          ahoyEventsCount: js.ahoyEventsCount,
          thisWeek: js.thisWeek,
          lastWeek: js.lastWeek,
          slug: js.slug
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              ahoyEventsCount: field.required("ahoyEventsCount", Json_Decode$JsonCombinators.$$int),
              thisWeek: field.required("thisWeek", Json_Decode$JsonCombinators.$$int),
              lastWeek: field.required("lastWeek", Json_Decode$JsonCombinators.$$int),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Analytics = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$2(js) {
  return {
          id: js.id,
          title: js.title,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          publishedAt: $$Date.Naive.fromString(js.publishedAt),
          approved: js.approved,
          slug: js.slug,
          category: js.category
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              publishedAt: field.required("publishedAt", $$Date.Naive.decoder),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Dashboard = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$3(js) {
  return {
          id: js.id,
          featuredImage: js.featuredImage,
          title: js.title,
          body: js.body,
          publishedAt: $$Date.Naive.fromString(js.publishedAt),
          url: js.url
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              featuredImage: field.required("featuredImage", Json_Decode$JsonCombinators.string),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              body: field.required("body", Json_Decode$JsonCombinators.string),
              publishedAt: field.required("publishedAt", $$Date.Naive.decoder),
              url: field.required("url", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Simple = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function fromJs$4(js) {
  return {
          id: js.id,
          title: js.title,
          publishedAt: $$Date.Naive.fromString(js.publishedAt),
          slug: js.slug
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              publishedAt: field.required("publishedAt", $$Date.Naive.decoder),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var Sidebar = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  fromJson: fromJson$4
};

function fromJs$5($$document) {
  return {
          id: $$document.id,
          filename: $$document.filename,
          byteSize: $$document.byteSize,
          createdAt: $$Date.Utc.fromString($$document.createdAt)
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", FeaturedImageId.decoder),
              filename: field.required("filename", Json_Decode$JsonCombinators.string),
              byteSize: field.required("byteSize", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Utc.decoder)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

function toKiloBytes(bytes) {
  return bytes / 1000.0;
}

function toMegaBytes(bytes) {
  return bytes / 1000000.0;
}

function sizeText(bytes) {
  if (bytes < 1000000) {
    return (bytes / 1000.0).toFixed(1) + " KB";
  } else {
    return (bytes / 1000000.0).toFixed(1) + " MB";
  }
}

var FeaturedImage = {
  fromJs: fromJs$5,
  decoder: decoder$5,
  fromJson: fromJson$5,
  toKiloBytes: toKiloBytes,
  toMegaBytes: toMegaBytes,
  sizeText: sizeText
};

function fromJs$6(js) {
  return {
          id: js.id,
          featuredImage: js.featuredImage,
          title: js.title,
          body: js.body,
          url: js.url,
          authorDescription: js.authorDescription,
          author: js.author,
          publishedAt: $$Date.Naive.fromString(js.publishedAt)
        };
}

export {
  FeaturedImageId ,
  Edit ,
  Analytics ,
  Dashboard ,
  Simple ,
  Sidebar ,
  FeaturedImage ,
  fromJs$6 as fromJs,
}
/* FeaturedImageId Not a pure module */
