// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as ServicesSeparatorBlockScss from "./ServicesSeparatorBlock.scss";

var css = ServicesSeparatorBlockScss;

function ServicesSeparatorBlock(props) {
  var title = props.title;
  if (title !== undefined) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("div", {
                        className: css.separator
                      }),
                  JsxRuntime.jsx("div", {
                        children: title,
                        className: css.separatorBox
                      }),
                  JsxRuntime.jsx("div", {
                        className: css.separator
                      })
                ],
                className: css.separatorWithTitle
              });
  } else {
    return JsxRuntime.jsx("div", {
                className: css.separator
              });
  }
}

var make = ServicesSeparatorBlock;

export {
  css ,
  make ,
}
/* css Not a pure module */
