// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as HorizontalScroll from "../HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIcon from "./components/BannerProviderIcon.res.js";
import * as BannerProviderIconsScss from "./BannerProviderIcons.scss";
import LogoH5Png from "Images/companies/logo-h5.png";
import LogoAwsSvg from "Images/companies/logo-aws.svg";
import LogoQtsPng from "Images/companies/logo-qts.png";
import LogoAzureSvg from "Images/companies/logo-azure.svg";
import LogoGoogleSvg from "Images/companies/logo-google.svg";
import LogoUcloudPng from "Images/companies/logo-ucloud.png";
import LogoAlibabaPng from "Images/companies/logo-alibaba.png";
import LogoEquinixPng from "Images/companies/logo-equinix.png";
import LogoTencentPng from "Images/companies/logo-tencent.png";
import LogoCoresitePng from "Images/companies/logo-coresite.png";
import LogoEvocativePng from "Images/companies/logo-evocative.png";
import LogoFlexentialPng from "Images/companies/logo-flexential.png";
import LogoCentersquarePng from "Images/companies/logo-centersquare.png";
import VerticalPng from "Images/companies/zenlayer/vertical.png";
import LogoControlPlaneSvg from "Images/companies/logo-control-plane.svg";
import LogoDigitalRealtyPng from "Images/companies/logo-digital-realty.png";
import LogoDatacentersComSvg from "Images/companies/logo-datacenters.com.svg";

var css = BannerProviderIconsScss;

function iconToComponent(icon) {
  switch (icon) {
    case "Alibaba" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoAlibabaPng,
                    alt: "Alibaba"
                  });
    case "AWS" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoAwsSvg,
                    alt: "AWS",
                    href: "/providers/amazon-aws"
                  });
    case "Azure" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoAzureSvg,
                    alt: "Microsoft Azure",
                    href: "/providers/microsoft-azure"
                  });
    case "Centersquare" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoCentersquarePng,
                    alt: "Centersquare",
                    href: "/providers/centersquare"
                  });
    case "ControlPlane" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoControlPlaneSvg,
                    alt: "Control Plane",
                    href: Routes_Page.controlPlaneSignUp
                  });
    case "Coresite" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoCoresitePng,
                    alt: "Coresite",
                    href: "/providers/coresite"
                  });
    case "Datacenters" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoDatacentersComSvg,
                    alt: "Datacenters.com",
                    href: "/"
                  });
    case "DigitalRealty" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoDigitalRealtyPng,
                    alt: "Digital Realty",
                    href: "/providers/digital-realty"
                  });
    case "Equinix" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoEquinixPng,
                    alt: "Equinix",
                    href: "/providers/equinix"
                  });
    case "Evocative" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoEvocativePng,
                    alt: "Evocative",
                    href: "/providers/evocative"
                  });
    case "Flexential" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoFlexentialPng,
                    alt: "Flexential",
                    href: "/providers/flexential"
                  });
    case "Google" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoGoogleSvg,
                    alt: "Google",
                    href: "/providers/google"
                  });
    case "H5" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoH5Png,
                    alt: "H5 Data Centers",
                    href: "/providers/h5-data-centers"
                  });
    case "Qts" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoQtsPng,
                    alt: "Qts",
                    href: "/providers/qts"
                  });
    case "Tencent" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoTencentPng,
                    alt: "Tencent"
                  });
    case "Ucloud" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoUcloudPng,
                    alt: "Ucloud"
                  });
    case "Zenlayer" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: VerticalPng,
                    alt: "Zenlayer",
                    href: "/providers/zenlayer"
                  });
    
  }
}

function BannerProviderIcons(props) {
  var tmp;
  switch (props.context) {
    case "BareMetal" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                iconToComponent("Zenlayer"),
                iconToComponent("AWS"),
                iconToComponent("Google"),
                iconToComponent("Azure"),
                iconToComponent("Alibaba"),
                iconToComponent("Ucloud"),
                iconToComponent("Tencent")
              ]
            });
        break;
    case "Colocation" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                iconToComponent("Qts"),
                iconToComponent("Coresite"),
                iconToComponent("Centersquare"),
                iconToComponent("Flexential"),
                iconToComponent("DigitalRealty"),
                iconToComponent("Equinix"),
                iconToComponent("H5")
              ]
            });
        break;
    case "Multicloud" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                iconToComponent("AWS"),
                iconToComponent("Google"),
                iconToComponent("Azure"),
                iconToComponent("ControlPlane"),
                iconToComponent("Datacenters")
              ]
            });
        break;
    case "Homepage" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                iconToComponent("AWS"),
                iconToComponent("Google"),
                iconToComponent("Azure"),
                iconToComponent("Coresite"),
                iconToComponent("Centersquare"),
                iconToComponent("DigitalRealty"),
                iconToComponent("Equinix"),
                iconToComponent("Evocative")
              ]
            });
        break;
    
  }
  return JsxRuntime.jsx(HorizontalScroll.make, {
              children: JsxRuntime.jsx("div", {
                    children: tmp,
                    className: css.wrapper
                  })
            });
}

var make = BannerProviderIcons;

export {
  css ,
  iconToComponent ,
  make ,
}
/* css Not a pure module */
