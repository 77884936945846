// Generated by ReScript, PLEASE EDIT WITH CARE

import * as HorizontalScroll from "../../../../../../../styleguide/components/HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronTitle from "../title/HomeJumbotronTitle.res.js";
import * as HomeJumbotronBoxesScss from "./HomeJumbotronBoxes.scss";

var css = HomeJumbotronBoxesScss;

function HomeJumbotronBoxes(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(HomeJumbotronTitle.make, {
                      title: props.title
                    }),
                JsxRuntime.jsx(HorizontalScroll.make, {
                      children: JsxRuntime.jsx("div", {
                            children: props.children,
                            className: css.boxes
                          }),
                      className: css.horizontalScroll
                    })
              ],
              className: css.container
            });
}

var make = HomeJumbotronBoxes;

export {
  css ,
  make ,
}
/* css Not a pure module */
