// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Country from "../../../../../routes/common/Routes_Country.res.js";
import * as ServiceSection from "../../../../../styleguide/components/ServiceSection/ServiceSection.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexLocality from "../../../../locations/index/components/common/LocationsIndexLocality.res.js";
import * as HomeTopCountriesSectionScss from "./HomeTopCountriesSection.scss";

var css = HomeTopCountriesSectionScss;

function HomeTopCountriesSection(props) {
  var countries = props.countries;
  return JsxRuntime.jsx(ServiceSection.make, {
              title: "Top " + String(countries.length) + " Countries with Data Centers",
              subtitle: "Search for data centers by country. Datacenters.com provides a comprehensive list of cities, states and countries with data centers.",
              background: "Gray",
              contentDirection: "Column",
              className: css.topCountriesSection,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      Belt_Array.mapWithIndex(countries, (function (index, country) {
                              return JsxRuntime.jsx(LocationsIndexLocality.make, {
                                          name: country.name,
                                          href: Routes_Country.show(country.slug),
                                          totalLocations: country.totalLocations
                                        }, String(index));
                            })),
                      JsxRuntime.jsx("a", {
                            children: "View All Countries",
                            className: css.countriesLink,
                            href: Routes_Country.index
                          })
                    ],
                    className: css.topCountries
                  })
            });
}

var make = HomeTopCountriesSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
