// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LazyImage from "../../../../../../../styleguide/components/LazyImage/LazyImage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronBoxScss from "./HomeJumbotronBox.scss";

var css = HomeJumbotronBoxScss;

function HomeJumbotronBox(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LazyImage.make, {
                      src: props.icon,
                      height: 60,
                      offset: 100,
                      className: css.icon
                    }),
                JsxRuntime.jsx("div", {
                      children: props.title,
                      className: css.title
                    }),
                JsxRuntime.jsx("div", {
                      children: props.message,
                      className: css.message
                    })
              ],
              className: css.box
            });
}

var make = HomeJumbotronBox;

export {
  css ,
  make ,
}
/* css Not a pure module */
