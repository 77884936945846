// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as Routes_BlogPost from "../../../../../routes/common/Routes_BlogPost.res.js";
import * as HomeBlogPostGrid from "./grid/HomeBlogPostGrid.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeBlogPostSectionScss from "./HomeBlogPostSection.scss";

var css = HomeBlogPostSectionScss;

function HomeBlogPostSection(props) {
  var blogPosts = props.blogPosts;
  console.log(blogPosts);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            className: css.title,
                            children: "Latest Data Center News"
                          }),
                      JsxRuntime.jsx("p", {
                            children: "Read the latest data center news about cloud computing, technology and more.",
                            className: css.description
                          }),
                      JsxRuntime.jsx(A.make, {
                            href: Routes_BlogPost.news,
                            className: css.allNews,
                            children: "Visit Data Center Blog"
                          }),
                      JsxRuntime.jsx(HomeBlogPostGrid.make, {
                            blogPosts: blogPosts
                          })
                    ],
                    className: css.container
                  }),
              className: css.section
            });
}

var make = HomeBlogPostSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
