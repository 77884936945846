// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIcons from "../../../../../styleguide/components/BannerProviderIcons/BannerProviderIcons.res.js";
import * as HomeProviderIconsScss from "./HomeProviderIcons.scss";

var css = HomeProviderIconsScss;

function HomeProviderIcons(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(BannerProviderIcons.make, {
                    context: "Homepage"
                  }),
              className: css.icons
            });
}

var make = HomeProviderIcons;

export {
  css ,
  make ,
}
/* css Not a pure module */
