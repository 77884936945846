// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../Link/Link.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIconsScss from "../BannerProviderIcons.scss";

var css = BannerProviderIconsScss;

function BannerProviderIcon(props) {
  return JsxRuntime.jsx("img", {
              className: css.icon,
              alt: props.alt,
              src: props.src
            });
}

function BannerProviderIcon$AsLink(props) {
  return JsxRuntime.jsx(Link.make, {
              href: props.href,
              children: JsxRuntime.jsx("img", {
                    className: css.icon,
                    alt: props.alt,
                    src: props.src
                  })
            });
}

var AsLink = {
  make: BannerProviderIcon$AsLink
};

var make = BannerProviderIcon;

export {
  css ,
  make ,
  AsLink ,
}
/* css Not a pure module */
